<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import ProgressDialog from './components/progress_dialog'



import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'


import {getPMApi} from '@/api/pm'
import { financial, autoComplete } from '@/api/misc'
import moment from 'moment'
//import Choices from "choices.js";

/**
 * User list component
 */
export default {
  page: {
    title: "RTA Payment",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      searched_data : [],
      
      pmList: [],
      unclosed_pm_list : [],
      filtered_pm_list : [],
      selected_pm : {
        pm_id : '',
        balance_available: 0,
        nr4              : 0,
        expense          : 0,
        commission       : 0,
        placement        : 0,
        balance_to_owner : 0,
        deposit_release  : 0
      },
      title: "PM",
      items: [
        {
          text: "PM",
          href: "/",
        },
        {
          text: "RTA Payment",
          active: true,
        },
      ],

      search_str : '',
      queryed_type : '',
      queryed_id   : '',

      filter :'',
      sortDesc : '',
      sortBy : '',

      totalRows: 0,
      currentPage: 1,
      perPage: 500,
      fields: [
        { key: "C", label :''},
        {
          key: "PMID",
          sortable: true,
        },
       
       
        {
          key: "LastCloseMonth",
          sortable: true,
        },
       
        {key : 'Deposit'},
        {key: "Balance",  sortable: true},
        {key: "Expense", },
        {key: "NR4", },
        {key: "Commission", },
        {key: "PlacmentFee"},
        {key: "DepositRelease", },
        {key: "Landlord", },
      
      ],

      payemnt_job_id : '',
      task_id : '',
      current_progress : 0,
    };
  },
  components: {
    Layout,
    PageHeader,
    ProgressDialog,
    VueBootstrapTypeahead
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },
  },

  created() {
   
  },
  mounted() {
   
  },
  unmounted() {
    clearInterval(this.task_id)
  },
  
  methods: {

    f(n, d = 2) {
      return financial(n, d)
    },

    queryList() {
      this.$bvModal.show('ProgressDiloag')
      let data = {
        queryed_type : this.queryed_type,
        queryed_id   : this.queryed_id
      }

      this.pmList = []
      
      getPMApi().query_pm_list_for_close_month_by_searched(data).then((res)=>{
        this.pmList = []
        this.$bvModal.hide('ProgressDiloag')
        if (res.errCode != 0) {
          this.$alertify.error("Query Payment PM  Failed " + res.errCode);
          return
        }
        res.data.map((o) => {
          if (o.landlord < 0 || o.balance_available < 0) {
            o._rowVariant   = 'danger'
            o.check_disable = true
            o.checked = false
            this.unclosed_pm_list.push({property_id : o.property_id})
          } else {
            o.check_disable = false
            o.checked = true
          }
         
          o.pay_to_owner  = o.landlord
          this.pmList.push(o)
         
        })

        this.totalRows = res.data.length
        this.filtered_pm_list = this.pmList

        this.$bvModal.hide('ProgressDiloag')
      })
    },

    submit_to_close() {

      this.$alertify.confirmWithTitle(
        "Confirmation",
        "Are you sure to Close this month?",
        () => {
         
          let pm_list = []
          this.filtered_pm_list.map(e => {
            if (e.checked == true) {
              pm_list.push(e.property_id)
            }
          })

          let data = {
            post_date     : moment().format("YYYY-MM-DD"),
            pm_list       : pm_list
          }


          this.$bvModal.show('CloseProgressDiloag')
          
          getPMApi().submit_payment(data).then(res => {
             if (res.errCode == 0) {
              clearInterval(this.task_id)
              this.payemnt_job_id = res.data.job_id
              this.task_id = setInterval(this.queryProgress, 2000)
             
             } else {
              clearInterval(this.task_id)
              this.$bvModal.hide('CloseProgressDiloag')
              this.$alertify.error("Close Month Failed " + res.errCode);
             }
          })

        },
      )

    },



    queryProgress() {
        
        getPMApi().payment_job_status_query({job_id : this.payemnt_job_id}).then(res => {
            if (res.errCode == 0) {
              if (res.data.error) {
                this.current_progress = 100
                clearInterval(this.task_id)
                this.$alertify.message("PM Close Failed, please re-do close ");
                this.$bvModal.hide('CloseProgressDiloag')
              } else {
                if (res.data.percentage == 100 || res.data.state =='FINISHED') {
                    this.$bvModal.hide('CloseProgressDiloag')
                    clearInterval(this.task_id)
                    this.$alertify.message("PM Payment successfully");
                    //this.$router.push({name : 'ap-pm-payment-list'})
                    this.filtered_pm_list =[]
                    this.pmList  = []
                } else {
                    this.current_progress = res.data.percentage
                }
              }
            } else {
                clearInterval(this.task_id)
            }
        })
    },

  

    onSelectAllPM(evt) {
      this.filtered_pm_list.map(e => {
        if (e.check_disable == false) {
          e.checked = evt.target.checked
        }
      })
    },

    onPMSelected(evt, pm) {
      pm.checked = evt.target.checked
      if (evt.target.checked == false) {
        let p =  this.unclosed_pm_list.find(e => e.property_id == pm.property_id)
        if (!p) {
          this.unclosed_pm_list.push({property_id : pm.property_id})
        }
      } else {
        this.unclosed_pm_list = this.unclosed_pm_list.filter(e => e.property_id != pm.property_id)
      }
    },





    onSearch(txt) {
      this.searched_data = []
      autoComplete({types : ['PM', 'AGENT'], str : txt}).then(res =>{
        if (res.errCode == 0) {
          res.data.map(e => {
            this.searched_data.push({
              str_id    : e.str_id,
              s_type    : e.s_type,
              obj       : e
            })
          })
         
        }
      })
    },

    onSearchedSelected(e) {
      
      this.queryed_type = e.obj.s_type
      this.queryed_id   = e.obj.s_type =='PM' ? e.obj.property_id : e.obj.id
      this.queryList()
    },

    
  },

  watch: {
  
    sortBy () {
     
      this.list_sort()
    },

    sortDesc () {
      this.list_sort()
    },

    search_str(search_str) {
      this.onSearch(search_str)
    },


  }

};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <b-modal centerd id="ProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
      <ProgressDialog   />
    </b-modal>

    <b-modal centerd id="CloseProgressDiloag" title="Waiting...." size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
      <b-progress height="20px" :value="current_progress"></b-progress>
    </b-modal>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div class="mb-3">
                  <h5 class="card-title">
                    Payment Count
                    <span class="text-muted fw-normal ms-2">({{ this.rows }})</span>
                  </h5>
                </div>
              </div>
              <!-- end col -->

              <div class="col-md-4">
                <vue-bootstrap-typeahead ref="supplier_name_ref"  
                              v-model="search_str"  :data="searched_data"
                              :serializer="s =>s.s_type == 'PM'? 'PM:' +s.str_id: 'Agent:' + s.str_id"
                              placeholder="Search by Agent or PM Code"
                              :foramterDisplay="s => s.str_id " @hit="onSearchedSelected($event)" autocomplete="off" />
              </div>

              <div class="col-md-6">
                <div
                  class="d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3"
                >
                  <b-button variant="secondary" @click="$router.push({name:'pm-property-list'})">Cancel</b-button>
                  <b-button variant="danger" @click="submit_to_close">Confirm Payment</b-button>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->

            <div class="table-responsive">
              <div class="table align-middle table-nowrap">
                <b-table
                  :items="pmList"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter-included-fields="['property_id']"
                  class="table-check datatables"
                  style="min-height: 50dvh;"
                >

                  <template  #head(C)>
                    <input
                        type="checkbox"  class="form-check-input"  id="checkAll" @change="onSelectAllPM($event)"  />
                  </template>
                  <template  #cell(C)="data">
                    <div class="form-check font-size-16">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        
                        :value ="data.item.property_id"
                        @change="onPMSelected($event, data.item)"
                        :disabled="data.item.check_disable"
                        :checked="data.item.check_disable == true? false : data.item.checked"
                      />
                      <label class="form-check-label" ></label>
                    </div>
                  </template>
                  <template  #cell(PMID)="data">
                    <router-link
                      :to="{ name : 'pm-overview', query: {pm_id : `${data.item.property_id}` }}"
                      class="text-body fw-medium" target="_blank"
                      >{{ data.item.property_id}}</router-link
                    >
                  </template>
                  <template #cell(Address)="data"> 
                    {{ data.item.address }}
                  </template>

                  <template #cell(PropertyManager)="data">
                    {{ `${data.item.agent.first_name} ${data.item.agent.last_name}` }}
                  </template>

                  <template #cell(LastCloseMonth)="data">
                    {{ data.item.last_payment_month }}
                  </template>

                  <template #cell(MonthlyRent)="data">
                    ${{ f(data.item.monthly_rent).toLocaleString() }}
                  </template>
                  <template #cell(Deposit)="data">
                    ${{ f(data.item.deposit).toLocaleString() }}
                  </template>
                  <template #cell(Balance)="data">
                    ${{ f(data.item.balance_available).toLocaleString() }}
                  </template>
                  <template #cell(Expense)="data">
                    ${{ f(data.item.expense).toLocaleString() }}
                  </template>
                  <template #cell(NR4)="data">
                    ${{ f(data.item.nr4_tax).toLocaleString() }}
                  </template>
                  <template #cell(Commission)="data">
                    ${{ f(data.item.commission).toLocaleString() }}
                  </template>
                  <template #cell(PlacmentFee)="data">
                    ${{ f(data.item.placement).toLocaleString() }}
                  </template>
                  <template #cell(DepositRelease)="data">
                    ${{ f(data.item.deposit_release).toLocaleString() }}
                  </template>

                  
                  
                  <template #cell(Landlord)="data">
                   ${{ f(data.item.pay_to_owner).toLocaleString()}}
                  </template>
                 
                </b-table>
              </div>
            </div>
            <!-- end table responsive -->

            <div class="row g-0 text-center text-sm-start">
              <!-- end col -->
              <div class="col-sm-12">
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-end"
                    >
                      <!-- pagination -->
                      <b-pagination
                        class="pagination-rounded"
                        v-model="currentPage"
                        :total-rows="totalRows"
                        :per-page="perPage"
                      ></b-pagination>
                    </div>
                  </div>
                </div>
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
